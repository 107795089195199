import { IFormInput } from "@/interface/common.interface";
import { userData } from "@/types/common.type";
import ApiRequest from "../axiosInstance/request";
import { endpoints } from "../endpoints";
import axiosInstance from "../axiosInstance";
// import { ProfileData } from "pages/dashboard/editprofile";
import { IFormInputModal } from "@/components/MuiModal/MuiModal";
import { ProfileData } from "@/components/Tab/Dashboard/Editprofile";

export const signUpMutation = async (body: IFormInput) => {
  const res = await axiosInstance.post(endpoints.auth.signup, body);
  return res;
};

export const signWithVerify = async (body: IFormInput) => {
  const res = await axiosInstance.post(endpoints.auth.emailVerify, body);
  return res;
};

export const resendOtp = async (body: IFormInput) => {
  const res = await axiosInstance.post(endpoints.auth.resendOtp, body);
  return res;
};

export const signInMutation = async (body: IFormInput) => {
  const res = await axiosInstance.post(endpoints.auth.signIn, body);
  return res;
};

export const forgetPasswordRequest = async (body: IFormInput) => {
  const res = await axiosInstance.post(
    endpoints.auth.forgetPasswordRequest,
    body
  );
  return res;
};

export const forgetPasswordVerify = async (body: IFormInput) => {
  const res = await axiosInstance.post(
    endpoints.auth.forgetPasswordVerify,
    body
  );
  return res;
};

export const resetPassword = async (body: IFormInput) => {
  const res = await axiosInstance.post(endpoints.auth.resetPassword, body);
  return res;
};

export const userDetails = async () => {
  // console.log('=============userDetails=========');
  const res = await axiosInstance.get(endpoints.auth.userDetails);
  // console.log('==============================res=========================',res)
  return res;
};

export const updateProfile = async (body: FormData) => {
  try {
    const res = await ApiRequest.post(endpoints.auth.updateProfile, body);

    return res;
  } catch (error) {
    return error;
  }
};

export const ProfileUpdateMutation = async (body: userData) => {
  try {
    const res = await ApiRequest.post(endpoints.auth.profileUpdate, body);

    return res;
  } catch (error) {
    return error;
  }
};

// export function SignUpUser(payload: IFormInput) {
//   const _res = axiosInstance
//     .post(endpoints.auth.signup, payload)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });

//   return _res;
// }

//===================================================================

export type PlayerData = {
  paddletap_id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  password: string;
  role: string;
  profile_pic: string;
  city: string | undefined;
  self_rating: string;
  self: boolean;
  emailOTP: string;
  phoneOTP: string;
  emailOTPExpire: string;
  deviceToken: string;
  deviceType: string;
  register_type: string;
  social_id: string;
  isMobileVerified: boolean;
  isEmailVerified: boolean;
  isSignupCompleted: boolean;
  isDeleted: boolean;
  isActive: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  player_exists_in_team:boolean;
};

export const playerAdd = async (body: PlayerData) => {
  try {
    const res = await ApiRequest.post(endpoints.player.playerAdd, body);

    return res;
  } catch (error) {
    return error;
  }
};

export const teamPlayerList = async () => {
  try {
    const res = await ApiRequest.post(endpoints.player.teamPlayerList, {});

    return res;
  } catch (error) {
    return error;
  }
};

export type playerListData = {
  search_user?: string;
  not_search_user?: string[];
};

export const playerList = async (data: playerListData) => {
  try {
    const res = await ApiRequest.post(endpoints.player.playerList, data);

    return res;
  } catch (error) {
    return error;
  }
};

export type teamPlayerAddData = {
  email: string;
};

export const teamPlayerAdd = async (data: teamPlayerAddData) => {
  try {
    const res = await ApiRequest.post(endpoints.player.teamPlayerAdd, data);

    return res;
  } catch (error) {
    return error;
  }
};

export type playerDelete = {
  email: string;
};

export const playerDelete = async (data: playerDelete) => {
  try {
    const res = await ApiRequest.post(endpoints.player.playerDelete, data);

    return res;
  } catch (error) {
    return error;
  }
};

// ====================Location=============================

export interface LocationResponse {
  status: number;
  data: LocationData;
  message: string;
}

export interface LocationData {
  title?: string;
  location_link?: string;
  locationtype?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  zipcode?: string;
  status?: string;
  lat?: string | number;
  long?: string | number;
  added_by?: string;
  isDeleted?: boolean;
  isApproved?: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
}

export const locationAdd = async (body: LocationData) => {
  try {
    const res = await ApiRequest.post(endpoints.location.locationAdd, body);

    return res;
  } catch (error) {
    return error;
  }
};

export const locationList = async () => {
  try {
    const res = await ApiRequest.post<any>(endpoints.location.locationList, {});

    return res;
  } catch (error) {
    return error;
  }
};

// allPublicLocation

export type allPublicLocationData = {
  search_user?: string;
  not_search_user?: string[];
};

export const allPublicLocation = async (data: allPublicLocationData) => {
  try {
    const res = await ApiRequest.post(
      endpoints.location.allPublicLocation,
      data
    );

    return res;
  } catch (error) {
    return error;
  }
};

export type myLocationAddData = {
  location_id: string;
};

export const myLocationAdd = async (data: myLocationAddData) => {
  try {
    const res = await ApiRequest.post(endpoints.location.myLocationAdd, data);

    return res;
  } catch (error) {
    return error;
  }
};

export type locationDeleteData = {
  locationid: string | undefined;
};

export const locationDeletee = async (data: locationDeleteData) => {
  try {
    const res = await ApiRequest.post<any>(
      endpoints.location.locationDelete,
      data
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const locationEditt = async (data: LocationData) => {
  try {
    const res = await ApiRequest.post(endpoints.location.locationEdit, data);

    return res;
  } catch (error) {
    return error;
  }
};

// locationEdit

// sessionAdd

export interface sessionAddResponse {
  status: number;
  data: sessionAddData;
  message: string;
}

export interface sessionAddData {
  _id?: string;
  locationid?: string;
  location_link?: string;
  date?: string;
  time?: string;
  time_format?: string;
  session_type?: string;
  gametypeid?: string;
  noofplayer?: number;
  noofcourt?: number;
  enablescoring?: boolean;
  courts?: string[];
  players?: string[];
  session_start?: boolean;
  session_end?: boolean;
  added_by?: string;
  status?: string;
  isDeleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const sessionAdd = async (data: sessionAddData) => {
  try {
    const res = await ApiRequest.post(endpoints.sessionAdd, data);

    return res;
  } catch (error) {
    return error;
  }
};

//gameList

export interface Root {
  status: number;
  data: GameListData[];
  message: string;
}

export interface GameListData {
  _id: string;
  title: string;
  status: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export const gameList = async () => {
  try {
    const res = await ApiRequest.post(endpoints.gameList, {});

    return res;
  } catch (error) {
    return error;
  }
};

// playerRandomize

export interface playerRandomizeData {
  players: string[];
}

export const playerRandomize = async (data: playerRandomizeData) => {
  try {
    const res = await ApiRequest.post(endpoints.playerRandomize, data);

    return res;
  } catch (error) {
    return error;
  }
};

// courtList

export interface courtListResponse {
  status: number;
  data: courtListData[];
  message: string;
}

export interface courtListData {
  _id: string;
  title: string;
  locationid: string;
  status: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  location_data: LocationData;
}

export interface courtListData2 {
  search_court?: string;
  not_search_court?: string;
}

export const courtList = async (data: courtListData2) => {
  try {
    const res = await ApiRequest.post(endpoints.courtList, data);
    return res;
  } catch (error) {
    return error;
  }
};

//score

export const score = async (data: any) => {
  try {
    const res = await ApiRequest.post<any>(endpoints.score, data);
    return res;
  } catch (error) {
    return error;
  }
};

//sessionEnd

export const sessionEnd = async (data: any) => {
  try {
    const res = await ApiRequest.post<any>(endpoints.sessionEnd, data);
    return res;
  } catch (error) {
    return error;
  }
};

// sessionDetails

export const sessionDetails = async (data: any) => {
  try {
    const res = await ApiRequest.post(endpoints.sessionDetails, data);
    return res;
  } catch (error) {
    return error;
  }
};

//scoreHistory

export const scoreHistory = async (data: any) => {
  try {
    const res = await ApiRequest.post(endpoints.scoreHistory, data);
    return res;
  } catch (error) {
    return error;
  }
};

// leaderBoard

export const leaderBoard = async () => {
  try {
    const res = await ApiRequest.post<any>(endpoints.leaderBoard, {});
    return res;
  } catch (error) {
    return error;
  }
};

//sessionList

export interface SessionListData {
  list_type: string;
  page_no: number;
  page_limit: number;
}

export const sessionList = async (data: SessionListData) => {
  try {
    const res = await ApiRequest.post(endpoints.sessionList, data);
    return res;
  } catch (error) {
    return error;
  }
};

// userDashboard

export const userDashboard = async () => {
  try {
    const res = await ApiRequest.get(endpoints.userDashboard);
    return res;
  } catch (error) {
    return error;
  }
};

//sessionDelete

export const sessionDelete = async (data: any) => {
  try {
    const res = await ApiRequest.post(endpoints.sessionDelete, data);
    return res;
  } catch (error) {
    return error;
  }
};

//socialSigninSignup

export const socialSigninSignup = async (data: any) => {
  try {
    const res = await ApiRequest.post<any>(
      endpoints.auth.socialSigninSignup,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

// userDelete

export const userDelete = async () => {
  try {
    const res = await ApiRequest.post<any>(endpoints.auth.userDelete, {});
    return res;
  } catch (error) {
    return error;
  }
};

//emailVerifyLink

interface EmailVerifyLinkData {
  id: string | string[] | undefined;
  otp: string | string[] | undefined;
}

export const emailVerifyLink = async (data: EmailVerifyLinkData) => {
  try {
    const res = await ApiRequest.get<any>(
      `${endpoints.auth.emailVerifyLink}?id=${data?.id}&otp=${data?.otp}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

// resendLink

interface ResendLinkData {
  id: string | string[] | undefined;
}

export const resendLink = async (data: ResendLinkData) => {
  try {
    const res = await ApiRequest.get<any>(
      `${endpoints.auth.resendLink}?id=${data?.id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

// changePassword

export interface ChangePasswordData {}

export interface ChangePasswordResponse {
  status: number;
  data: ChangePasswordData;
  message: string;
}

export const changePassword = async (body: IFormInputModal) => {
  const res = await axiosInstance.post<ChangePasswordResponse>(
    endpoints.auth.changePassword,
    body
  );
  return res;
};

export const getGroupList = async () => {
  const res = await axiosInstance.post<any>(endpoints.group.list, {});
  return res;
};

export const getGroupUpdate = async (data: any) => {
  const res = await axiosInstance.post<any>(endpoints.group.update, data);
  return res;
};

export const getGroupDetails = async (data: any) => {
  const res = await axiosInstance.post<any>(endpoints.group.details, data);
  return res;
};

export const playerAddDelete = async (data: any) => {
  const res = await axiosInstance.post<any>(
    endpoints.group.player_add_delete,
    data
  );
  return res;
};

// player_exist

export const playerExist = async () => {
  const res = await axiosInstance.post<any>(endpoints.group.player_exist, {});
  return res;
};

// group_leader_board

export const groupLeaderBoard = async (group_id: string) => {
  const res = await axiosInstance.post<any>(
    endpoints.group.group_leader_board,
    {
      group_id,
    }
  );
  return res;
};

// groupAdd

export const groupAdd = async (data:any) => {
  const res = await axiosInstance.post<any>(
    endpoints.group.add,
    data
  );
  return res;
};

// groupDelete

export const groupDelete = async (data:any) => {
  const res = await axiosInstance.post<any>(
    endpoints.group.delete,
    data
  );
  return res;
};

