export default {
  notfound: "/assets/images/404.svg",
  logo: "/vercel.svg",
  testicon: "/assets/icons/2082.png",
  testicon1: "/assets/icons/icon-192x192.png",
  testicon2: "/assets/icons/icon-256x256.png",
  testicon3: "/assets/icons/icon-384x384.png",
  testicon4: "/assets/icons/icon-512x512.png",
  image: "/assets/icons/auricular-phone_318-1028.webp",
  logo_image: "/assets/images/logo.svg",
  ftr_logo: "/assets/images/ftr.svg",
  search_icon: "/assets/images/srch.svg",
  social_icon: "/assets/images/fb.svg",
  social_icon2: "/assets/images/fb2.svg",
  social_icon3: "/assets/images/fb3.svg",
  social_icon4: "/assets/images/fb4.svg",
  msgIcon: "/assets/images/msg.svg",
  aboutImage: "/assets/images/abt.png",
  aboutImage2: "/assets/images/abt2.png",
  bannerImage: "/assets/images/ban.png",
  bannerImage2: "/assets/images/ban2.png",
  bannerP: "/assets/images/p1.svg",
  bannerP2: "/assets/images/p2.svg",
  graphic1: "/assets/images/Graphic1.png",
  graphic2: "/assets/images/Graphic2.png",
  graphic3: "/assets/images/gr2.png",
  graphic4: "/assets/images/gr3.png",
  team1: "/assets/images/t1.png",
  team2: "/assets/images/t2.png",
  team3: "/assets/images/t3.png",
  team4: "/assets/images/t4.svg",
  team5: "/assets/images/t5.svg",
  team6: "/assets/images/t6.svg",
  prev: "/assets/images/prev.svg",
  next: "/assets/images/next.svg",
  downArrow: "/assets/images/down.svg",
  icon1: "/assets/images/ic1.svg",
  icon2: "/assets/images/ic2.svg",
  icon3: "/assets/images/ic3.svg",
  icon4: "/assets/images/ic4.svg",
  lock: "/assets/images/lock.svg",
  google: "/assets/images/google.svg",
  facebook: "/assets/images/facebook.svg",
  apple: "/assets/images/apple.svg",
  user: "/assets/images/user.svg",
  pass: "/assets/images/pass.svg",
  reset: "/assets/images/reset.svg",
  profile_image: "/assets/images/user.png",
  down_arrw: "/assets/images/arrw.svg",
  dashicon1: "/assets/images/d1.svg",
  dashicon2: "/assets/images/d2.svg",
  dashicon3: "/assets/images/d3.svg",
  dashicon4: "/assets/images/d4.svg",
  dashicon5: "/assets/images/d5.svg",
  dashicon6: "/assets/images/d6.svg",
  dashicon7: "/assets/images/d7.svg",
  dashicon8: "/assets/images/d8.svg",
  dashicon9: "/assets/images/deleteUser.png",
  user2: "/assets/images/usr2.png",
  pen: "/assets/images/pen.svg",
  tableImage: "/assets/images/t1.svg",
  buttonthreedot: "/assets/images/buttonthreedot.svg",
  calendar_icon: "/assets/images/calendar.svg",
  select_twoArw: "/assets/images/select_twoArw.svg",
  teamIcon1: "/assets/images/te1.png",
  teamIcon2: "/assets/images/te2.png",
  gameImage: "/assets/images/gm.svg",
  gameImage2: "/assets/images/gm2.png",
  gameImage3: "/assets/images/gm3.png",
  rgtArrow: "/assets/images/rgt.svg",
  delete_box: "/assets/images/delete_box.svg",
  user_imgss: "/assets/images/user_img.svg",
  add_plus_bttn: "/assets/images/add_plus_bttn.svg",
  court_imgs: "/assets/images/court_imgs.svg",
  locate_green: "/assets/images/locate_green.svg",
  vs_img: "/assets/images/vs_img.svg",
  layer_back_top: "/assets/images/layer_back_top.png",
  panel_shape_left: "/assets/images/panel_shape_left.png",
  file_pdf: "/assets/images/file_pdf.svg",
  file_pdf2: "/assets/images/file_pdf2.svg",
  addIcon: "/assets/images/addIcon.svg",
  plyr1: "/assets/images/plyr9.png",
  // plyr1: "/assets/images/plyr1.png",
  plyr2: "/assets/images/plyr2.png",
  plyr3: "/assets/images/plyr3.png",
  plyr4: "/assets/images/plyr4.png",
  plyr5: "/assets/images/plyr5.png",
  plyr6: "/assets/images/plyr6.png",
  plyr7: "/assets/images/plyr7.png",
  plyr8: "/assets/images/plyr8.png",
  plyr9: "/assets/images/plyr9.png",

  delete_Icon: "/assets/images/delete_Icon.svg",
  edit_icon: "/assets/images/edit_icon.svg",
  wincup: "/assets/images/wincup.svg",
  lossCup: "/assets/images/lossCup.svg",
  location_blue: "/assets/images/location_blue.svg",
  calendar_blue: "/assets/images/calendar_blue.svg",
  id_icon: "/assets/images/id_icon.svg",
  about_logo: "/assets/images/about_logo.svg",
  arrow_link: "/assets/images/arrow_link.svg",
  email_icon: "/assets/images/email_icon.svg",
  location_edit: "/assets/images/location_edit.svg",
  location_delete: "/assets/images/location_delete.svg",
  share_icon: "/assets/images/share_icon.svg",
  lock_2: "/assets/images/lock_2.svg",
  player_img_large: "/assets/images/player_img_large.png",
  calendar: "/assets/images/icons8-calendar-48.png",
  up_arrow: "/assets/images/up-arrow.png",
  refresh: "/assets/images/refresh-button.png",
  paddle: "/assets/images/Group_1707481012.png",
  delete_user: "/assets/images/delete_user.svg",
  setting_icon: "/assets/images/setting_icon.svg",
  contact_mail: "/assets/images/contact_mail.svg",
  google_playstore: "/assets/images/google_playstore.png",
  apple_appstore: "/assets/images/Layer_2.png",
  Pickle_Ball:"/assets/images/Pickle_Ball.png",
  wifi:'/assets/images/wifi.svg'
};
