import { setLoginData } from '@/reduxtoolkit/slices/userSlice';
import { parseCookies } from 'nookies';
import { useQuery } from 'react-query';
import { useAppDispatch } from './useAppDispatch';
import { userDetails } from '@/api/functions/user.api';

const useUser = () => {

    // console.log('=================Hello2==========================')

    // const { userData } = useAppSelector(s => s.userSlice)
    const cookies = parseCookies();
    const token = cookies?.token;
    // console.log(token)
    const userDetails2 = cookies?.userDetails
    const dispatch = useAppDispatch()

    const res = useQuery('userDetails', {
        queryFn: () => userDetails(),
        enabled: !!token,
        onSuccess: (data) => {
            // console.log('data?.data *********',data?.data)


            dispatch(setLoginData(data?.data))
        }
    })

    return res;
}

export default useUser