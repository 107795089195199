export const baseUrl = process.env.NEXT_APP_BASE_URL;
export const baseUrlApi = `${process.env.NEXT_APP_BASE_URL}/api/`;
export const baseUrlMedia = process.env.NEXT_APP_BASE_URL;
export const mediaUrl = (url: string) => {
  return `${baseUrlMedia}/uploads/${url}`;
};

// api doc => https://militarymoves-admin.dedicateddevelopers.us/apidoc

export const endpoints = {
  auth: {
    signup: "user/signup",
    signIn: "user/signin",
    emailVerify: "user/email-verify",
    profileUpdate: "user/profile/update",
    userDetails: "user/details",
    resendOtp: "user/resend-otp",
    forgetPasswordRequest: "user/forget-password-request",
    forgetPasswordVerify: "user/forget-password-verify",
    resetPassword: "user/reset-password",
    updateProfile: "user/update-profile",
    socialSigninSignup: "user/socialSigninSignup",
    userDelete: "user/delete",
    emailVerifyLink: "user/email-verify-link",
    resendLink: "user/resend-link",
    changePassword: "user/change-password",
  },
  cms: {
    about: "cms/about",
    web_about: "cms/web_about",
    web_about_teams: "teams/getlist",
    faq: "faq/list",
    home: "home",
    contact: "contact/add",
    rules: "cms/rules",
    terms: "generalcms/details",
  },
  player: {
    playerAdd: "user/player_add",
    teamPlayerList: "user/team_player_list",
    playerList: "user/player_list",
    teamPlayerAdd: "user/team_player_add",
    playerDelete: "user/player_delete",
  },
  location: {
    locationAdd: "location/location_add",
    locationList: "location/location_list",
    allPublicLocation: "location/all_public_location",
    myLocationAdd: "user/my-location_add",
    locationDelete: "location/location_delete",
    locationEdit: "location/location_edit",
  },
  sessionAdd: "session/session_add",
  sessionDelete: "session/session_delete",
  gameList: "gametype/list",
  playerRandomize: "user/player_randomize",
  courtList: "court/court_list",
  score: "session/score",
  sessionEnd: "session/session_end",
  sessionDetails: "session/session_details",
  scoreHistory: "session/score-history",
  leaderBoard: "user/leader-board",
  sessionList: "session/session_list",
  userDashboard: "user/user-dashboard",
  setting: {
    settingGetlist: "setting/getlist",
  },
  group: {
    list: "user/group/list",
    update: "user/group/update",
    details: "user/group/details",
    player_add_delete: "user/group/player_add_remove",
    player_exist: "user/group/list/player_exist",
    group_leader_board: "user/group-leader-board",
    add:"user/group/add",
    delete:"user/group/delete"
  },
};
