
import useUser from "@/hooks/useUser";
import { ReactElement } from "react";

interface AuthWrapperProps {
    children: JSX.Element | JSX.Element[];
}
const AuthWrapper = (props: AuthWrapperProps) => {
    const { isLoading } = useUser();

    // console.log('=================Hello==========================')


    return <>
        {/* <Backdrop sx={{ color: "#fff" }} open={isLoading}>
            <CircularProgress color="primary" />
        </Backdrop> */}
        {props.children}
    </>
};

export default AuthWrapper;